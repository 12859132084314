import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0,2,3,14,4,5,6,7,8,9,10,11,12];

export const dictionary = {
		"/": [~15],
		"/(app)/administration": [16,[2,3]],
		"/(app)/administration/reporting/user-log": [~17,[2,3]],
		"/(auth)/forgot-password": [~41,[13,14]],
		"/(auth)/forgot-password/[token]": [~42,[13,14]],
		"/(auth)/login": [~43,[13]],
		"/(auth)/logout": [44,[13]],
		"/(app)/profile/changePassword": [~18,[2,4]],
		"/(app)/profile/my-data": [19,[2,4]],
		"/(auth)/register": [~45,[13]],
		"/(app)/studies": [~20,[2,5]],
		"/(app)/studies/create-study": [40,[2,5]],
		"/(app)/studies/[id]": [~21,[2,5,6]],
		"/(app)/studies/[id]/edit-study": [22,[2,5,6]],
		"/(app)/studies/[id]/management/adjudication/create-tasks": [~29,[2,5,6]],
		"/(app)/studies/[id]/management/adjudication/worklist-management": [~30,[2,5,6]],
		"/(app)/studies/[id]/management/(study-setup)/collaborators": [23,[2,5,6,7]],
		"/(app)/studies/[id]/management/(study-setup)/collaborators/add": [~25,[2,5,6,7]],
		"/(app)/studies/[id]/management/(study-setup)/collaborators/[collaborator_id]/edit": [24,[2,5,6,7,8]],
		"/(app)/studies/[id]/management/qc/create-tasks": [~31,[2,5,6]],
		"/(app)/studies/[id]/management/qc/worklist-management": [~32,[2,5,6]],
		"/(app)/studies/[id]/management/reads/create-tasks": [~33,[2,5,6]],
		"/(app)/studies/[id]/management/reads/worklist-management": [~34,[2,5,6]],
		"/(app)/studies/[id]/management/(study-setup)/sites": [26,[2,5,6,9]],
		"/(app)/studies/[id]/management/(study-setup)/sites/create": [28,[2,5,6,9]],
		"/(app)/studies/[id]/management/(study-setup)/sites/[site_id]/edit": [27,[2,5,6,9,10]],
		"/(app)/studies/[id]/reporting/audit-trail": [~35,[2,5,6]],
		"/(app)/studies/[id]/reporting/queries": [~36,[2,5,6]],
		"/(app)/studies/[id]/worklist": [~37,[2,5,6]],
		"/(app)/studies/[id]/worklist/[task_id]/report": [~39,[2,5,6,11]],
		"/(app)/studies/[id]/worklist/[task_id]/[worksheet_id]": [38,[2,5,6,11,12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';