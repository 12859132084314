import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { env } from '$env/dynamic/public';

const PUBLIC_SENTRY_DSN = env.PUBLIC_SENTRY_DSN || 'ENV_LOAD_ERROR';
const PUBLIC_LAUNCHDARKLY_CLIENT_ID = env.PUBLIC_LAUNCHDARKLY_CLIENT_ID || 'ENV_LOAD_ERROR';

export let client: LaunchDarkly.LDClient | null = null;

if (PUBLIC_SENTRY_DSN && PUBLIC_SENTRY_DSN !== 'ENV_LOAD_ERROR') {
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()]
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();

if (PUBLIC_LAUNCHDARKLY_CLIENT_ID && PUBLIC_LAUNCHDARKLY_CLIENT_ID !== 'ENV_LOAD_ERROR') {
  client = LaunchDarkly.initialize(PUBLIC_LAUNCHDARKLY_CLIENT_ID, {
    key: 'anonymous'
  });
}
